export const BROKER_URL="https://stock-api2.fnotrader.com/v1/"
export const FNOTRADER_URL="https://trade-api.fnotrader.com/v1/"
export const ZERODHA_URL="https://api.kite.trade/"
// export const BASE_URL="http://localhost:5290/v1/api/"
// export const BASE_SIGNALR_HUB="http://localhost:5290/notification"
//export const BASE_URL="https://localhost:7290/api/"
export const BASE_URL="https://trade.fnotrader.com/v1/api/"
export const BASE_SIGNALR_HUB="https://trade.fnotrader.com/notification"



