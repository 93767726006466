import React from 'react';
import { Spinner } from 'reactstrap'; // You can use any library for icons or spinners

const LoaderComponent = () => {
  return (
    <div className="loader-overlay">
    <div className="loader">
        
    </div>
  </div>
  );
};

export default LoaderComponent;